<template>
  <el-dialog
    custom-class="common-dialog"
    :visible="visible"
    :title="title"
    @opened="openInit"
    @close="handleClose"
    width="800px"
    :close-on-click-modal="false"
  >
    <div>
      <div class="table-area">
        <cus-table
          ref="cusTableRef"
          :tableParams="tableParams"
          :tableData="accountList"
          :colData="colData"
        >
        </cus-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cusTable from "@/views/components/cusTable.vue";
import {accounts} from '@/api/mainPage'
export default {
  name: "adAccountInfo",
  components: { cusTable },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "标题",
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    page_id:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      total: 0,
      tableParams: {
        border: true,
        // height: "300px",
      },
      accountList:[],
      colData: [
        {
          label: "广告账户",
          prop: "name",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "广告账户Id",
          prop: "id",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "开户日期",
          prop: "create_time",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "使用状态",
          prop: "accountStatus",
          align: "center",
          headerAlign: "center",
        },
      ],
    };
  },
  methods: {
    openInit(){
      accounts({id:this.page_id}).then(res=>{
        console.log(res);
        this.accountList = res.data.filter(v=>v.accountStatus == 1);
        this.accountList.map(v=>{
          v.accountStatus = v.accountStatus == 1?'可用':'不可用'
        })
      })
    },
    handleClose() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style>
</style>