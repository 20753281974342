<template>
  <div class="search-form">
    <el-form
      size="small"
      :inline="true"
      :model="formModel"
      :rules="formRules"
      ref="formRef"
    >
      <el-form-item prop="id">
        <el-input
          clearable
          placeholder="主页id"
          v-model="formModel.id"
        ></el-input>
      </el-form-item>
      <el-form-item prop="name">
        <el-input
          clearable
          placeholder="主页名称"
          v-model="formModel.name"
        ></el-input>
      </el-form-item>
      <el-form-item prop="status">
        <el-select
          filterable
          clearable
          placeholder="状态"
          v-model="formModel.status"
        >
          <el-option
            :label="item.value"
            :value="item.key"
            v-for="(item, index) in statusList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="status">
        <el-select
          filterable
          clearable
          placeholder="主页发布状态"
          v-model="formModel.is_published"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in publishStatus"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="status">
        <el-select
          filterable
          clearable
          placeholder="主页评分"
          v-model="formModel.star_level"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in starLevel"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="third_user_email">
        <!-- <el-input placeholder="运营个号" v-model="formModel.third_user_email"></el-input> -->
        <el-select
          filterable
          clearable
          multiple
          placeholder="运营个号"
          @focus="getUser"
          v-model="formModel.third_user_ids"
          style="width:300px"
        >
          <el-option
            :label="(item.displayName || item.name) +'('+ item.email+')' "
            :value="item.id"
            v-for="(item, index) in users"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="third_user_email">
        <!-- <el-select
          filterable
          clearable
          placeholder="运营小组"
          v-model="formModel.group_id"
          style="width:300px"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in groupList"
            :key="index"
          ></el-option>
        </el-select> -->
        <el-cascader
            :options="options"
            :props="{ multiple: true, checkStrictly: true ,value:'code',label:'name',children:'childs',expandTrigger:'hover'}"
            style="width: 380px"
            clearable
            ref="orgs"
            filterable
            collapse-tags="true"
          ></el-cascader>
      </el-form-item>
      <!-- <el-form-item prop="updateTime">
        <el-date-picker
          v-model="timeList"
          type="daterange"
          range-separator="至"
          start-placeholder="最后更新开始时间"
          end-placeholder="最后更新结束时间"
          :default-time="['00:00:00', '23:59:59']"
        >
        </el-date-picker>
      </el-form-item> -->
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch"
          >查询</el-button
        >
      </el-form-item>
    </el-form>
  </div>                                                                                                                                                                                                                                                     
</template>

<script>
import {myThirdUsers,groups} from '@/api/mainPage'
import { orgTree} from "@/api/operateGroupSetting";
import {fnCSTToYmd} from '@/utils/utils';
export default {
  name:"searchForm",
  props: {
    statusList: {
      type: Array,
      default: () => [],
    },
    publishStatus:{
      type:Array,
      default: () => [],
    },
    groupList:{
      type:Array,
      default: () => [],
    }
  },
  data() {
    return {
      agents: [],
      accounts: [],
      webs: [],
      statues: [],
      formModel: {},
      formRules: {},
      users:[],
      timeList:[],
      options:[],
      starLevel:[
        {id:'NO_STAR',name:'无评分'},
        {id:'LOW_STAR',name:'0~2分'},
        {id:'HIGH_STAR',name:'2~5分'},
      ]
    };
  },
  mounted(){
    this.getGroupList();
  },
  methods: {
    getUser(){
      myThirdUsers().then(res=>{
        this.users = res.data;
      })
    },
    getGroupList() {
      groups().then((res) => {
        let data = res.data;
        this.options = data;
      });
    },
    handleSearch() {
      if(this.timeList.length){
        this.formModel.last_update_time_start = fnCSTToYmd(new Date(this.timeList[0]))
        this.formModel.last_update_time_end = fnCSTToYmd(new Date(this.timeList[1]))
      }else{
        this.formModel.last_update_time_start = null
        this.formModel.last_update_time_end = null
      }
      for(let key in this.formModel){
        this.formModel[key] = this.formModel[key] !== ''?this.formModel[key]:null;
      }
      console.log(this.$refs['orgs'].getCheckedNodes());
      let orgs = this.$refs['orgs'].getCheckedNodes()?this.$refs['orgs'].getCheckedNodes().map(v=>v.data.code):[]
      console.log(this.formModel);
      this.formModel.orgs = orgs;
      this.$emit('setParams',this.formModel)
    },
  },
};
</script>

<style lang="scss" scoped>
.el-form {
  padding: 16px 16px 0;
  .el-form-item {
    margin-bottom: 16px;
  }
}
</style>