<template>
  <el-dialog
    custom-class="common-dialog"
    :visible="visible"
    :title="title"
    @opened="openInit"
    @close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      size="small"
      :model="formModel"
      :rules="formRules"
      label-width="120px"
      ref="formRef"
    >
      <el-form-item label="主页" prop="name">
        <el-input
          clearable
          placeholder="主页"
          v-model="formModel.name"
          disabled
        ></el-input>
      </el-form-item>
      <el-form-item label="状态" prop="status">
        <el-select
          filterable
          clearable
          nultiple
          placeholder="状态"
          v-model="formModel.status"
        >
          <el-option
            :label="item.value"
            :value="item.key"
            v-for="(item, index) in statusList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">关闭</el-button>
      <el-button size="small" type="primary" @click="handleConfirm"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {edit} from '@/api/mainPage'
export default {
  name: "editMainPage",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "标题",
    },
    initData: {
      type: Object,
      default: () => {},
    },
    statusList:{
      type: Array,
      default: () => [],
    }
  },
  data() {
    return {
      statuses: [],
      formModel: {
        name: "",
        status: "",
      },
      name:'',
      formRules: {
        status: {required:true,message:'请选择状态',trigger:'blur'},
      },
    };
  },
  methods: {
    openInit(){
      this.formModel.name = this.initData.name;
      this.formModel.status = this.initData.status
    },
    handleClose() {
      this.$emit("close-dialog");
    },
    handleConfirm() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          edit({status:this.formModel.status,id:this.initData.id}).then(res=>{
            if(res.code == 0){
              this.$message({
                type:'success',
                message:'修改成功'
              })
              this.handleClose();
              this.$emit('getList');
            }
          })
          console.log("this.formModel:", this.formModel);
        }
      });
    },
  },
  watch: {
    
  },
};
</script>

<style lang="scss">
</style>