<template>
  <el-dialog
    custom-class="common-dialog"
    :visible="visible"
    :title="title"
    @opened="openInit"
    @close="handleClose"
    width="800px"
    :close-on-click-modal="false"
  >
    <div>
      <div class="table-area">
        <cus-table
          ref="cusTableRef"
          :tableParams="tableParams"
          :tableData="batchsList"
          :colData="colData"
        >
        <template v-slot:colslot="{ scope, prop }">
          <div v-if="prop == 'status'">
            {{ openStatues.find((s) => s.id == scope.row[prop]).name }}
          </div>
        </template>
        </cus-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">关闭</el-button>
    </span>
  </el-dialog>
</template>

<script>
import cusTable from "@/views/components/cusTable.vue";
import {batchs} from '@/api/mainPage'
export default {
  name: "adAccountInfo",
  components: { cusTable },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "标题",
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    page_id:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      total: 0,
      tableParams: {
        border: true,
        // height: "300px",
      },
      accountList:[],
      colData: [
        {
          label: "主体名称",
          prop: "batchName",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "开户网站",
          prop: "webSite",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "优化",
          prop: "creator_name",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "开户批次号",
          prop: "batch_num",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "开户代理",
          prop: "agent",
          align: "center",
          headerAlign: "center",
        },
        {
          label: "开户结果",
          prop: "status",
          align: "center",
          headerAlign: "center",
        },
        
      ],
      openStatues: [
        { id: 'SUCCESS', name: "成功" },
        { id: 'FAIL', name: "失败" },
        { id: 'APPLYING', name: "申请中" },
        { id: 'SUBMITTED', name: "已提交" },
      ],
      batchsList:[]
    };
  },
  methods: {
    openInit(){
      batchs({id:this.page_id}).then(res=>{
        console.log(res);
        this.batchsList = res.data;
        this.batchsList.map(v=>{
            v.batchName = v.subject.name;
            v.webSite = v.webSite.full_url;
            v.agent = v.agent.name;
        })
      })
    },
    handleClose() {
      this.$emit("close-dialog");
    },
  },
};
</script>

<style>
</style>