<template>
  <el-dialog
    custom-class="common-dialog"
    :visible="visible"
    :title="title"
    @opened="openInit"
    @close="handleClose"
    width="700px"
    :close-on-click-modal="false"
  >
    <el-form
      size="small"
      :inline="true"
      :model="formModel"
      :rules="formRules"
      ref="formRef"
      class="addMember"
    >
      <el-form-item label="通知人员：" prop="user_ids">
        <el-select
          filterable
          clearable
          multiple
          placeholder="通知人员"
          v-model="formModel.user_ids"
        >
          <el-option
            :label="item.name"
            :value="item.id"
            v-for="(item, index) in membersList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">关闭</el-button>
      <el-button size="small" type="primary" @click="handleConfirm"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {members,setting} from '@/api/mainPage'
export default {
  name: "batchTipSet",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "标题",
    },
    initData: {
      type: Object,
      default: () => {},
    },
    ids:{
      type:Array,
      default:()=>[]
    },
    users:{
      type:Array,
      default:()=>[]
    },
  },
  data() {
    return {
      peoples:[],
      formModel: {
        user_ids: [],
      },
      formRules: {
        user_ids: {required:true,message:'请选择通知人员',trigger:'blur'},
      },
      membersList:[]
    };
  },
  methods: {
    handleClose() {
      this.$refs.formRef.resetFields();
      this.$emit("close-dialog");
    },
    openInit(){
      this.$refs.formRef.resetFields();
      members().then(res=>{
        this.membersList = res.data;
        this.formModel.user_ids = [...this.users];
      })
      this.$nextTick(()=>{
          this.$refs.formRef.clearValidate();
        })
    },
    handleConfirm() {
      console.log(this.ids);
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          setting({user_ids:this.formModel.user_ids,page_ids:this.ids}).then(res=>{
            console.log(res);
            if(res.code == 0){
              this.$message({
                type:'success',
                message:'添加成功'
              })
              this.handleClose();
              this.$emit('getList');
            }
          })
          console.log("this.formModel:", this.formModel);
        }
      });
    },
  },
  watch: {
    initData: {
      handler(val) {
        console.log("watch-initData", val);
        Object.keys(this.formModel).forEach((item) => {
          this.formModel[item] = val[item];
        });
      },
    },
  },
};
</script>

<style lang="scss" >
.addMember{
  .el-select{
    width: 500px;
  }
}
</style>