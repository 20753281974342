<template>
  <el-dialog
    custom-class="common-dialog"
    :visible="visible"
    :title="title"
    @close="handleClose"
    @opened="openInit"
    width="800px"
    :close-on-click-modal="false"
  >
    <div>
      <div class="header">
        <el-button type="text" icon="el-icon-folder-add" @click="addNum"
          >添加运营个号</el-button
        >
      </div>
      <div class="table-area">
        <cus-table
          ref="cusTableRef"
          :tableParams="tableParams"
          :tableData="tableData"
          :colData="colData"
        >
          <template v-slot:colslot="{ scope, prop }">
            <div v-if="prop == 'operate'">
              <el-link @click="delRow(scope.row)" type="danger"
                ><i class="el-icon-delete"></i>删除</el-link
              >
            </div>
            <div v-else-if="prop == 'userType'">
              <span>{{userTypesList.find(v=>v.key == scope.row.userType).value}}</span>
            </div>
          </template>
        </cus-table>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">关闭</el-button>
    </span>
    <!-- 添加运营个号 -->
    <add-num
      :visible="addNumVisible"
      title="添加运营个号"
      :userTypesList="userTypesList"
      :page_id="page_id"
      @initData="initData"
      @close-dialog="addNumVisible = false"
    ></add-num>
  </el-dialog>
</template>

<script>
import cusTable from "@/views/components/cusTable.vue";
import addNum from "./addNum.vue";
import {delThirdUsers} from '@/api/mainPage'
export default {
  name: "numInfoPreserve",
  components: { cusTable, addNum },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "标题",
    },
    numList: {
      type: Object,
      default: () => {},
    },
    colData: {
      type: Array,
      default: () => [],
    },
    userTypesList:{
      type: Array,
      default: () => [],
    },
    page_id:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      total: 0,
      tableParams: {
        border: true,
        // height: "300px",
      },
      addEditMainPageVisible: false,
      dialogTitle: "",
      editInitData: {},
      addNumVisible: false,
      tableData:[]
    };
  },
  methods: {
    handleClose() {
      this.$emit("close-dialog");
    },
    initData(v){
      let tableData = JSON.parse(JSON.stringify(v));
        tableData.map((v,i)=>{
          tableData[i] = {...v,...v.thirdLoginUser.thirdUser}
        })
        this.tableData = JSON.parse(JSON.stringify(tableData))
        console.log(this.tableData);
        this.$emit('refreshCol',v)
    },
    openInit(){
      console.log(this.numList);
      if(this.numList){
        this.initData(this.numList.thirdUserAndTypes)
      }else{
        this.tableData = [];
      }
    },
    delRow(row) {
      let name = row.thirdUserName;
      this.$confirm(`此操作将永久删除个号：${name}, 是否继续?`, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          delThirdUsers({page_id:this.page_id,third_user_id:row.thirdUserId,user_type:row.userType}).then(res=>{
            if(res.code == 0){
              this.$message({
                type: "success",
                message: `个号：${name}删除成功!`,
              });
              this.initData(res.data.thirdUserAndTypes);
              this.$emit('refreshCol',res.data.thirdUserAndTypes)
            }
          })
          
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: `已取消删除个号：${name}`,
          });
        });
    },
    addNum() {
      this.addNumVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: flex-end;
}
</style>