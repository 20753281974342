<template>
  <el-dialog
    custom-class="common-dialog"
    :visible="visible"
    :title="title"
    append-to-body
    @close="handleClose"
    :close-on-click-modal="false"
  >
    <el-form
      size="small"
      :model="formModel"
      :rules="formRules"
      label-width="120px"
      ref="formRef"
    >
      <el-form-item label="个号：" prop="third_user_id">
        <el-select
          filterable
          clearable
          nultiple
          placeholder="个号"
          @focus="getUser"
          v-model="formModel.third_user_id"
        >
          <el-option
            :label="item.thirdUser.thirdUserName"
            :value="item.thirdUser.thirdUserId"
            v-for="(item, index) in users"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色：" prop="user_type">
        <el-select
          filterable
          clearable
          nultiple
          placeholder="角色"
          v-model="formModel.user_type"
        >
          <el-option
            :label="item.value"
            :value="item.key"
            v-for="(item, index) in userTypesList"
            :key="index"
          ></el-option>
        </el-select>
      </el-form-item>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="handleClose">关闭</el-button>
      <el-button size="small" type="primary" @click="handleConfirm"
        >确认</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
import {thirdUsers,addThirdUsers} from '@/api/mainPage'
export default {
  name: "addNum",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "标题",
    },
    initData: {
      type: Object,
      default: () => {},
    },
    userTypesList:{
      type:Array,
      default:() =>[]
    },
    page_id:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      nums: [],
      roles: [],
      formModel: {},
      formRules: {
        user_type:{required:true,message:'请选择角色',trigger:'change'},
        third_user_id:{required:true,message:'请选择个号',trigger:'change'},
      },
      users:[]
    };
  },
  methods: {
    handleClose() {
      this.$emit("close-dialog");
    },
    handleConfirm() {
      this.$refs.formRef.validate((valid) => {
        if (valid) {
          console.log("this.formModel:", this.formModel);
          addThirdUsers({...this.formModel,page_id:this.page_id}).then(res=>{
            console.log(res);
            if(res.code == 0){
              this.$message({
                type:'success',
                message:'添加成功'
              })
              this.handleClose();
              this.$emit('initData',res.data.thirdUserAndTypes)
            }
          })
        }
      });
    },
    getUser(){
      thirdUsers().then(res=>{
        this.users = res.data;
      })
    }
  },
  watch: {
    initData: {
      handler(val) {
        console.log("watch-initData", val);
        Object.keys(this.formModel).forEach((item) => {
          this.formModel[item] = val[item];
        });
      },
    },
  },
};
</script>

<style lang="scss">
</style>